<template>
  <sub-page
      :title="$t('ProjectParticipants')"
      icon="mdi-folder"
      v-model="$store.state.settings.pageData"
      :header-action="openAddParticipant"
      :header-action-text="$t('projects.participants.edit')"
  >
    <ws-data-table
        :items="items"
        :headers="headers"
        class="mt-3"
    >
      <template #item.name="{item}">
        <ws-user-data :item="item" />
      </template>

      <template #item.roles="{item}">
        <h5 v-if="item.is_owner">{{ $t('Owner') }}</h5>
        <h5 v-if="item.is_admin">{{ $t('Administrator') }}</h5>
        <h5 v-if="item.is_controller">{{ $t('ProjectController') }}</h5>
      </template>

      <template #item.owner_total="{item}">
        <h5> {{ item.owner_completed }}/{{ item.owner_total }}</h5>
      </template>

      <template #item.performer_total="{item}">
        <h5> {{ item.performer_completed }}/{{ item.performer_total }}</h5>
      </template>

      <template #item.hover="{item,hover}">
        <div style="width : 25px">
          <v-btn
              v-if="hover"
              @click="() => notify(item)"
              icon
              :color="wsACCENT"
          >
            <v-icon>mdi-dots-horizontal</v-icon>
          </v-btn>
        </div>
      </template>

    </ws-data-table>

  </sub-page>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "ProjectParticipants",
  props : {
    uuid : {
      type : String,
      default : ''
    }
  },
  data() {
    return {
      entity : {},
      items : [],
      displayDialog : false
    }
  },

  computed : {
    headers() {
      return [
        { text : this.$t('Name') , value : 'name' },
        { text : this.$t('Roles') , value : 'roles' , width : 10 , align : 'center'},
        { text : this.$t('AssignedTasks') , value : 'owner_total' , width : 10 ,align : 'center' },
        { text : this.$t('PerformedTasks') , value : 'performer_total' , width : 10 ,align : 'center'},
        { value : 'hover' , width : 10 , sortable : false},

      ]
    },
  },
  methods : {
    ...mapActions('project_manager', [
      'GET_PROJECT_PARTICIPANTS',
    ]),
    openAddParticipant() {
      this.displayDialog = true
    },
    async initPage() {
      let result = await this.GET_PROJECT_PARTICIPANTS(this.uuid)
      if ( !result ) {
        this.ERROR()
      }
      this.items = result.items
    }
  },
  mounted() {
    this.initPage()
  }

}
</script>

<style scoped>

</style>