var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('sub-page',{attrs:{"title":_vm.$t('ProjectParticipants'),"icon":"mdi-folder","header-action":_vm.openAddParticipant,"header-action-text":_vm.$t('projects.participants.edit')},model:{value:(_vm.$store.state.settings.pageData),callback:function ($$v) {_vm.$set(_vm.$store.state.settings, "pageData", $$v)},expression:"$store.state.settings.pageData"}},[_c('ws-data-table',{staticClass:"mt-3",attrs:{"items":_vm.items,"headers":_vm.headers},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('ws-user-data',{attrs:{"item":item}})]}},{key:"item.roles",fn:function(ref){
var item = ref.item;
return [(item.is_owner)?_c('h5',[_vm._v(_vm._s(_vm.$t('Owner')))]):_vm._e(),(item.is_admin)?_c('h5',[_vm._v(_vm._s(_vm.$t('Administrator')))]):_vm._e(),(item.is_controller)?_c('h5',[_vm._v(_vm._s(_vm.$t('ProjectController')))]):_vm._e()]}},{key:"item.owner_total",fn:function(ref){
var item = ref.item;
return [_c('h5',[_vm._v(" "+_vm._s(item.owner_completed)+"/"+_vm._s(item.owner_total))])]}},{key:"item.performer_total",fn:function(ref){
var item = ref.item;
return [_c('h5',[_vm._v(" "+_vm._s(item.performer_completed)+"/"+_vm._s(item.performer_total))])]}},{key:"item.hover",fn:function(ref){
var item = ref.item;
var hover = ref.hover;
return [_c('div',{staticStyle:{"width":"25px"}},[(hover)?_c('v-btn',{attrs:{"icon":"","color":_vm.wsACCENT},on:{"click":function () { return _vm.notify(item); }}},[_c('v-icon',[_vm._v("mdi-dots-horizontal")])],1):_vm._e()],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }